import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Spinner } from "@/components/Elements";
import { MainLayout } from "@/components/Layout";
import { lazyImport } from "@/utils/lazyImport";

const { Dashboard } = lazyImport(() => import("@/features/misc"), "Dashboard");
const { Success } = lazyImport(() => import("@/features/misc"), "Success");
const { AuditsSearch } = lazyImport(
  () => import("@/features/auditReview"),
  "AuditsSearch",
);
const { HandHygieneRoutes } = lazyImport(
  () => import("@/features/handHygiene"),
  "HandHygieneRoutes",
);
const { FireDrillRoutes } = lazyImport(
  () => import("@/features/firedrill"),
  "FireDrillRoutes",
);
const { MedRoomChecklistRoutes } = lazyImport(
  () => import("@/features/medRoomChecklist"),
  "MedRoomChecklistRoutes",
);
const { EnvironmentalChecklistRoutes } = lazyImport(
  () => import("@/features/environmentalChecklist"),
  "EnvironmentalChecklistRoutes",
);

const App = () => {
  return (
    <MainLayout>
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "fire/*",
        element: <FireDrillRoutes />,
      },
      {
        path: "hygiene/*",
        element: <HandHygieneRoutes />,
      },
      {
        path: "medroom/*",
        element: <MedRoomChecklistRoutes />,
      },
      {
        path: "environment/*",
        element: <EnvironmentalChecklistRoutes />,
      },
      {
        path: "success",
        element: <Success />,
      },
      {
        path: "search/*",
        element: <AuditsSearch />,
      },
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "*",
        element: <Navigate to={"."} />,
      },
    ],
  },
];
