import { Flex, Spinner as ChakraSpinner, Text } from "@chakra-ui/react";

type SpinnerProps = {
  label?: string;
};

export const Spinner = ({ label = "Loading..." }: SpinnerProps) => {
  return (
    <Flex
      // width={"100vw"}
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      width="full"
      pt={32}
    >
      <ChakraSpinner
        color="brand"
        boxSize={24}
        speed="0.65s"
        emptyColor="gray.400"
        thickness="10px"
      />
      <Text fontWeight="bold" fontSize="2xl" marginY={10}>
        {label}
      </Text>
    </Flex>
  );
};
