import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";

import { RootState } from "@/store/index.ts";
import { FormData } from "@/types";

type FormState<T> = {
  data: Partial<T>;
  page: number;
};

const createFormSlice = <T, Reducers extends SliceCaseReducers<FormState<T>>>({
  name = "",
  initialState,
  reducers,
}: {
  name: string;
  initialState: FormState<T>;
  reducers: ValidateSliceCaseReducers<FormState<T>, Reducers>;
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      setData: (state: FormState<T>, action: PayloadAction<Partial<T>>) => {
        state.data = {
          ...state.data,
          ...action.payload,
        };
      },
      incrementPage: (state: FormState<T>) => {
        state.page += 1;
      },
      decrementPage: (state: FormState<T>) => {
        state.page -= 1;
      },
      setPage: (state: FormState<T>, action: PayloadAction<number>) => {
        state.page = action.payload;
      },
      resetForm: () => initialState,
      ...reducers,
    },
  });
};

const formSlice = createFormSlice({
  name: "form",
  initialState: {
    data: {},
    page: 0,
  } as FormState<FormData>,
  reducers: {},
});

export const { setData, incrementPage, decrementPage, setPage, resetForm } =
  formSlice.actions;

export const selectData = (state: RootState) => state.form.data;
export const selectPage = (state: RootState) => state.form.page;
export const formReducer = formSlice.reducer;
